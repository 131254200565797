interface ModalCard {
  readonly title: string;
  readonly content: string | readonly string[];
}

interface ModalContent {
  readonly title: string;
  readonly websiteUrl: string;
  readonly cards: readonly ModalCard[];
}

export const MODAL_CONTENT: Readonly<Record<string, ModalContent>> = {
  moneyVehicle: {
    title: "Money Vehicle",
    websiteUrl: "https://yourmoneyvehicle.com/",
    cards: [
      {
        title: "About Money Vehicle",
        content: "Money Vehicle is an innovative education company dedicated to closing the financial literacy gap in the United States by providing high school students and young adults with an engaging, entertaining, and empowering personal finance program. Recognizing the need for financial education, Money Vehicle has developed a comprehensive, interactive curriculum tailored to the needs of today's youth, setting them up for lifelong financial success."
      },
      {
        title: "Mission & Vision",
        content: [
          "Mission: Building an easy to deliver and engaging to use financial literacy curriculum.",
          "Vision: Closing the financial literacy gap and empowering students to start a plan!"
        ] as const
      },
      {
        title: "Founder",
        content: "Jedidiah Collins CFP® – Former NFL Player, Certified Financial Planner®, best-selling author Your Money Vehicle, adjunct professor, and founder of Money Vehicle."
      },
      {
        title: "Expert Team & Approach",
        content: "Their experienced team, consisting of former financial journalists, advisors, educators, instructional designers, and entrepreneurs, has created a unique blend of interactive textbook content, standards-based curriculum, and educational resources suitable for in-classroom instruction across the country. This hybrid approach to on and off-screen learning enables students to develop their financial plans and gain a thorough understanding of personal finance."
      },
      {
        title: "Program Features",
        content: [
          "Currently being delivered in Personal Finance, CTE, Econ, FCS, Life Skills, or Financial Algebra classrooms in over 20 states",
          "Teacher Resources include pacing guides, facilitator guides, student workbook, interactive textbook, virtual content, and all assessments",
          "Content maps to state or national standards",
          "Covers the first 10 actions a student should take to begin a financial plan",
          "Delivers the message through entertaining analogies",
          "Integration available through Canvas or Schoology with SSO for Google Class LMS"
        ] as const
      }
    ] as const
  },
  cwPublications: {
    title: "CW Publications",
    websiteUrl: "https://cwpubonline.com/",
    cards: [
      {
        title: "About CW Publications",
        content: "CW Publications helps teachers and administrators prepare upper middle schoolers, high schoolers and young adults for the real world with interactive CD Rom programs. These programs include real life scenarios, simulations and interactive exercises throughout each of the titles."
      },
      {
        title: "Personal Finance Programs",
        content: "Our personal finance programs cover essential life skills including money management and budgeting in banking, credit, insurance, and shopping for essentials like food, clothing, apartments and automobiles."
      },
      {
        title: "Career Preparation",
        content: "Career preparation and decision making programs focus on critical aspects of starting a career, making first impressions, and achieving success at work."
      },
      {
        title: "Parenting Education",
        content: "Our parenting programs provide comprehensive guidance on creating a baby-safe home, raising young children, and understanding various parenting situations and terminology."
      }
    ]
  },
  techworks4Good: {
    title: "Techworks 4 Good",
    websiteUrl: "https://techworks4good.com/",
    cards: [
      {
        title: "About Techworks 4 Good",
        content: "Techworks 4 Good (Florida Corporation, A DBA of Ecovation Global Holdings, LLC) is a technology provider company dedicated to making technology accessible to all. Our mission, \"Technology for All: Bridging the Gap with Innovation,\" drives us to ensure that no community is left behind in the rapidly advancing digital era. We firmly believe in the transformative power of technology and its potential to create positive change."
      },
      {
        title: "Our Focus",
        content: "Using the SkillForge IQ platform, we focus on improving health, job skills, and literacy for all. This includes the following groups: adults with disabilities, at-risk populations, prison populations, non-English speakers, veterans, and learners of all ages."
      },
      {
        title: "Reading XR",
        content: "Reading XR is a reading fluency screening tool that delivers objective results that are unmeasurable using traditional reading tests. Eye Tracking tools play an essential role in evaluating factors affecting reading comprehension, a proven concept for over 30 years. The test results provide valuable insight into the underlying factors that can cause reading inefficiencies. Testing can be accomplished up to 4x faster than conventional testing methods with Reading XR saving testing administrators hundreds of hours a year."
      },
      {
        title: "Medical IP",
        content: "Medical IP, a pioneering company at the forefront of healthcare innovation, provides FDA-cleared solutions that integrate Whole-Body Composition Analysis and leverage Advanced Medical Education by Digital Twin with a remarkable blend of cutting-edge technologies. Medical IP's MDBOX is an innovative medical metaverse solution that enables an immersive experience in human anatomy education. By implementing anatomical structures on a digital twin in the VR space, breaking free from physical limitations, and creating a new paradigm in the field of medical education."
      }
    ] as const
  },
  mathMedia: {
    title: "MathMedia",
    websiteUrl: "https://mathmedia.com/",
    cards: [
      {
        title: "About MathMedia",
        content: "MathMedia academic software delivers K through College self-paced instructional tutorial math education software with straightforward interactive learning and easy-to-use assessment tools. The hallmark of the curriculum is the careful guidance of students, done in a friendly visual language, to complete understanding of all the \"whys\" of mathematics."
      },
      {
        title: "Product Line",
        content: "This 50-program mathematics education computer software product line provides an organized-systematic-structured learning system for young learners and adults with: elementary arithmetic software, basic math software, pre-algebra software, algebra software (algebra 1 and algebra 2), geometry software, trigonometry, advanced math, reading and critical thinking skills, SAT prep, ACT prep, TABE / GED prep software, and CLEP prep software."
      }
    ]
  },
  cg1Solutions: {
    title: "CG1 Solutions",
    websiteUrl: "https://cg1solutions.com/",
    cards: [
      {
        title: "About CG1 Solutions",
        content: "Charlie Golf One, LLC d/b/a CG1 Solutions (CG1) is a technology firm specializing in cutting-edge technologies (i.e. extended reality [XR]). Based in South Florida, CG1 Solutions is a Service-Disabled Veteran-Owned Small Business (SDVOSB) that provides innovative solutions to empower education, enterprise, and direct-to-consumer industries."
      },
      {
        title: "IQ3 Connect Platform",
        content: "IQ3 Connect offers a web-based solution that serves as a foundational layer for XR curriculum development. Educational institutions, individuals, and enterprises can leverage IQ3's platform to create, deploy, and collaborate with immersive and interactive 3D content on any VR, AR, PC, or mobile device. IQ3Connect's productivity tools facilitate instant 3D collaboration in virtual workspaces and enable the no-code creation of immersive training and experiences in minutes, making it an ideal resource for XR curriculum integration."
      },
      {
        title: "Singular Health's 3DICOM XR",
        content: "Singular Health creates technologies that provide educators and students with personalized, enhanced medical data to improve health literacy. Their 3DICOM R&D is a comprehensive DICOM viewer for 3D visualization, segmentation, and 3D printing. It can convert 2D medical images into 3D reconstructions, transform DICOMs to JPG/PNG, and enable segmentation for 3D printing of anatomical structures. Teachers can utilize 3Dicom's extensive library to enhance their biomedical STEM curriculum."
      }
    ] as const
  },
  instructionalSystems: {
    title: "Instructional Systems",
    websiteUrl: "https://isinj.com/",
    cards: [
      {
        title: "About Instructional Systems",
        content: "Instructional Systems, Inc. has created a series of hybrid, web/CDRom-based programs to prepare high school to adult students for the workforce with unique multimedia courses in office skills, customer service, health occupations, security and the construction trades."
      },
      {
        title: "Comprehensive Management System",
        content: "There is in-depth reporting through an extensive management system included with each of these courses. ISI also offers high quality occupation-specific, college remedial, and workplace basic skills courseware for adolescents to adults. This courseware has been used extensively in schools, community colleges, correctional facilities, vocational training centers, community-based organizations, as well as in business and private industry."
      },
      {
        title: "Technology Integration",
        content: "ISI's training product is unique in its utilization of web-based and network technology, allowing program staff and instructors to track the progress of participants on a daily basis and to provide personalized assistance where most needed. The courseware maximizes the benefits of modern telecommunications infrastructure allowing participants to learn and communicate with staff at any time."
      },
      {
        title: "Course Features",
        content: "Each of the ISI courses features individualized multi-media instruction, self-paced learning, competency-based instruction, and built-in glossaries with audio support. ISI's comprehensive web-based and local area network-based learning management systems provide automated reporting on participant progress. Data is available 24/7 for review by program managers and staff, and the data may be used for reporting and evaluation purposes."
      }
    ]
  },
  filmsMediaGroup: {
    title: "Films Media Group",
    websiteUrl: "https://films.com/dealers/",
    cards: [
      {
        title: "About Films Media Group",
        content: "As the leading provider of media for higher education, Films for the Humanities & Sciences creates and distributes world-class academic content for discerning educators. Films for the Humanities & Sciences is widely recognized as having the broadest and deepest range of content available."
      },
      {
        title: "Academic Disciplines",
        content: [
          "Anthropology",
          "Area Studies",
          "Art & Architecture",
          "Biology",
          "Business & Economics",
          "Careers & Job Search",
          "Communication",
          "Computers & Technology",
          "Criminal Justice",
          "Earth Science",
          "Education",
          "English & Language Arts",
          "Environmental Science",
          "Family & Consumer Sciences",
          "Geography",
          "Guidance & Counseling",
          "Health",
          "History",
          "Mathematics",
          "Music",
          "Philosophy & Religion",
          "Physical Science",
          "Political Science",
          "Psychology",
          "Sociology",
          "Technical Education",
          "World Languages"
        ] as const
      }
    ] as const
  },
  nycEnglish: {
    title: "NYC English",
    websiteUrl: "https://nycenglish.nyc/",
    cards: [
      {
        title: "About NYC English",
        content: "NYC English is a leading provider of English language education, offering comprehensive programs designed to help students achieve fluency and confidence in English communication. Their innovative approach combines traditional teaching methods with modern technology to create an effective learning environment."
      },
      {
        title: "Program Features",
        content: [
          "Interactive learning platform",
          "Experienced native English-speaking teachers",
          "Flexible class schedules",
          "Personalized learning paths",
          "Focus on practical communication skills",
          "Regular progress assessments"
        ] as const
      },
      {
        title: "Learning Approach",
        content: "NYC English employs a communicative approach to language learning, emphasizing real-world applications and practical usage. Students engage in interactive exercises, role-playing scenarios, and authentic conversations to develop their language skills naturally and effectively."
      }
    ] as const
  }
} as const;
